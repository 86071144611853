import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SunSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00008 0.666992V2.00033M8.00008 14.0003V15.3337M2.81341 2.81366L3.76008 3.76033M12.2401 12.2403L13.1867 13.187M0.666748 8.00033H2.00008M14.0001 8.00033H15.3334M2.81341 13.187L3.76008 12.2403M12.2401 3.76033L13.1867 2.81366M11.3334 8.00033C11.3334 9.84127 9.84103 11.3337 8.00008 11.3337C6.15913 11.3337 4.66675 9.84127 4.66675 8.00033C4.66675 6.15938 6.15913 4.66699 8.00008 4.66699C9.84103 4.66699 11.3334 6.15938 11.3334 8.00033Z"
      stroke="#4E4E4E"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SunIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon rev={undefined} component={SunSvg} {...props} />
);
