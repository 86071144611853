import {
  endpoints,
  ListEntity,
  seatersAdminPrefix,
  TranslationListEntity,
  TranslationsQuery,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchTranslations = (
  params: TranslationsQuery
): Promise<ListEntity<TranslationListEntity>> =>
  api.get(`${endpoints.app}/${endpoints.translations}`, { params });

export const importPhraseAppTranslations = (): Promise<unknown> =>
  api.put(`${seatersAdminPrefix}/${endpoints.importAllTranslations}`);
