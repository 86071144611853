import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const MoonSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9999 7.52667C12.8951 8.66147 12.4692 9.74294 11.7721 10.6445C11.075 11.5461 10.1356 12.2305 9.06374 12.6177C7.99188 13.0049 6.83192 13.0787 5.7196 12.8307C4.60728 12.5827 3.5886 12.023 2.78275 11.2172C1.97691 10.4113 1.41723 9.39265 1.16921 8.28033C0.921185 7.16801 0.995078 6.00806 1.38224 4.9362C1.7694 3.86434 2.45382 2.92491 3.35541 2.22784C4.257 1.53076 5.33847 1.10487 6.47327 1C5.80888 1.89884 5.48917 3.0063 5.57229 4.12094C5.65541 5.23559 6.13584 6.28337 6.9262 7.07373C7.71656 7.86409 8.76435 8.34452 9.87899 8.42765C10.9936 8.51077 12.1011 8.19106 12.9999 7.52667Z"
      stroke="#9C9C9C"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MoonIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon rev={undefined} component={MoonSvg} {...props} />
);
