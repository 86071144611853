import { importPhraseAppTranslations } from '@seaters-app/data-access';
import { Button, Flex, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
const { Title } = Typography;

function Localization() {
  const { t } = useTranslation();

  const importTranslations = async () => {
    try {
      await importPhraseAppTranslations();
      notification.success({
        message: t('admin_translations_phraseapp-import-done'),
      });
    } catch (error) {
      notification.error({
        message: error?.message || t('general_error_text'),
      });
    }
  };

  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
    >
      <Title level={2}>{t('admin_menu_localization')}</Title>

      <Button
        onClick={importTranslations}
        style={{ width: '250px' }}
        type="primary"
      >
        {t('admin_translations_import-all-from-phraseapp')}
      </Button>
    </Flex>
  );
}

export default Localization;
