import {
  AxiosAPIError,
  ListEntity,
  ProtectionCodeEntity,
  CodesQuery,
  CodeValidator,
  fanGroupsKeys,
  SortedPaginationQueryV2,
  ListEntityV2,
  ProtectionCodeEnhancedEntity,
  EnhancedCodesQuery,
  CustomInfoItem,
  ProtectionCodeCustomInfo,
} from '@seaters-app/constants';

import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createFanGroupProtectionCode,
  deleteFanGroupProtectionCode,
  fetchFanGroupProtectionCodes,
  fetchFanGroupProtectionCodesBadges,
  fetchFanGroupProtectionCodesCustomInfo,
  fetchFanGroupProtectionCodesEnhanced,
  fetchFanGroupProtectionCodesEnhancedAsFGO,
  linkFanGroupProtectionCodeToBadge,
  linkFanGroupProtectionCodeToCustomInfo,
  unlinkFanGroupProtectionCodeBadge,
  unlinkFanGroupProtectionCodeCustomInfo,
} from '../api/fanGroupProtectionCodes';

export const useFetchFanGroupProtectionCodes = (
  fanGroupId: string,
  params: CodesQuery
) =>
  useQuery<ListEntity<ProtectionCodeEntity>, Error>(
    fanGroupsKeys.codesList(fanGroupId, params),
    () => fetchFanGroupProtectionCodes(fanGroupId, params),
    {
      keepPreviousData: true,
      enabled: !!fanGroupId,
    }
  );

export const useFetchFanGroupProtectionCodesEnhanced = (
  fanGroupId: string,
  params: EnhancedCodesQuery
) =>
  useQuery<ListEntityV2<ProtectionCodeEnhancedEntity>, Error>(
    fanGroupsKeys.enhancedCodesList(fanGroupId, params),
    () => fetchFanGroupProtectionCodesEnhanced(fanGroupId, params),
    {
      keepPreviousData: true,
      enabled: !!fanGroupId,
    }
  );

export const useFetchFanGroupProtectionCodesEnhancedAsFGO = (
  fanGroupId: string,
  params: EnhancedCodesQuery
) =>
  useQuery<ListEntityV2<ProtectionCodeEnhancedEntity>, Error>(
    fanGroupsKeys.enhancedCodesList(fanGroupId, params),
    () => fetchFanGroupProtectionCodesEnhancedAsFGO(fanGroupId, params),
    {
      keepPreviousData: true,
      enabled: !!fanGroupId,
    }
  );

export const useFetchFanGroupProtectionCodesBadges = (
  fanGroupId: string,
  params: SortedPaginationQueryV2
) =>
  useQuery<ListEntityV2<ProtectionCodeEntity>, Error>(
    fanGroupsKeys.codesBadgesList(fanGroupId),
    () => fetchFanGroupProtectionCodesBadges(fanGroupId, params),
    {
      keepPreviousData: true,
      enabled: !!fanGroupId,
    }
  );

export const useFetchFanGroupProtectionCodesCustomInfo = (
  fanGroupId: string,
  params: SortedPaginationQueryV2
) =>
  useQuery<ListEntityV2<ProtectionCodeCustomInfo>, Error>(
    fanGroupsKeys.codesCustomInfoList(fanGroupId),
    () => fetchFanGroupProtectionCodesCustomInfo(fanGroupId, params),
    {
      keepPreviousData: true,
      enabled: !!fanGroupId,
    }
  );

export const useCreateFanGroupProtectionCode = (
  fanGroupId: string
): UseMutationResult<
  ProtectionCodeEntity,
  AxiosAPIError<CodeValidator>,
  CodeValidator,
  string
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: CodeValidator) => createFanGroupProtectionCode(fanGroupId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.enhancedCodes(fanGroupId));
      },
    }
  );
};

export const useDeleteFanGroupProtectionCode = (fanGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (code: string) => deleteFanGroupProtectionCode(fanGroupId, code),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.enhancedCodes(fanGroupId));
      },
    }
  );
};

export const useLinkFanGroupProtectionCodeToCustomInfo = (
  fanGroupId: string
): UseMutationResult<
  ProtectionCodeEntity,
  AxiosAPIError<{
    code: string;
    body: CustomInfoItem;
  }>,
  {
    code: string;
    body: CustomInfoItem;
  },
  string
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ code, body }: { code: string; body: CustomInfoItem }) =>
      linkFanGroupProtectionCodeToCustomInfo(fanGroupId, code, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.enhancedCodes(fanGroupId));
      },
    }
  );
};

export const useUnlinkFanGroupProtectionCodeCustomInfo = (
  fanGroupId: string
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ code, semanticName }: { code: string; semanticName: string }) =>
      unlinkFanGroupProtectionCodeCustomInfo(fanGroupId, code, semanticName),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.enhancedCodes(fanGroupId));
      },
    }
  );
};

export const useLinkFanGroupProtectionCodeToBadge = (
  fanGroupId: string
): UseMutationResult<
  ProtectionCodeEntity,
  AxiosAPIError<{
    code: string;
    badgeId: string;
  }>,
  {
    code: string;
    badgeId: string;
  },
  string
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ code, badgeId }: { code: string; badgeId: string }) =>
      linkFanGroupProtectionCodeToBadge(fanGroupId, code, badgeId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.enhancedCodes(fanGroupId));
      },
    }
  );
};

export const useUnlinkFanGroupProtectionCodeBadge = (fanGroupId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ code, badgeId }: { code: string; badgeId: string }) =>
      unlinkFanGroupProtectionCodeBadge(fanGroupId, code, badgeId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fanGroupsKeys.enhancedCodes(fanGroupId));
      },
    }
  );
};
