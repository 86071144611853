import { Typography, Tabs, TabsProps, Breadcrumb, Flex } from 'antd';
import styles from './layout.module.css';
import { routes, WaitingListStatusEnum } from '@seaters-app/constants';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useFanGroupStore,
  useFetchFanGroupOwnerWaitingList,
  useFetchLook,
} from '@seaters-app/data-access';

const { Title } = Typography;

export function EditWishListLayout() {
  const { t } = useTranslation();

  const { wishListId = '' } = useParams();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { data: wishList } = useFetchFanGroupOwnerWaitingList(wishListId);

  const { fanGroup: storedFanGroup } = useFanGroupStore();
  const { data: lookData } = useFetchLook(storedFanGroup?.slug ?? '');

  const onChangeTab = (key: string) => {
    navigate(key);
  };

  const items: TabsProps['items'] = [
    {
      key: routes.general,
      label: t('admin_tabs_general'),
    },
    {
      key: routes.filterConfig,
      label: t('mwl_tab-filters'),
    },
    {
      key: routes.tickets,
      label: t('group_numberofseats'),
    },
    {
      key: routes.profiling,
      label: t('profiling_label_text'),
    },
    {
      key: routes.badges,
      label: t('admin_badges'),
    },
    {
      key: routes.surveys,
      label: t('admin_surveys'),
    },
    {
      key: routes.publish,
      label: t('mwl_tab-publish'),
    },
  ];

  const statusesToShow = [
    WaitingListStatusEnum.DRAFT,
    WaitingListStatusEnum.SETUP,
    WaitingListStatusEnum.PUBLISHED,
  ];

  const tabsVisibility = {
    [routes.general]: true,
    [routes.tickets]: true,
    [routes.profiling]: true,
    [routes.badges]: true,
    [routes.surveys]: true,
    [routes.publish]: wishList?.status
      ? statusesToShow.includes(wishList.status)
      : false,
    [routes.filterConfig]: lookData?.properties?.filters?.length,
  };

  const activeTab = items?.find((item) => pathname.includes(item.key));

  const visibleTabs = items?.filter((item) => tabsVisibility[item.key]);

  return (
    <Flex
      vertical
      style={{
        padding: '24px 24px 80px',
      }}
    >
      <div>
        <Breadcrumb
          items={[
            {
              title: (
                <Link to={`/${routes.admin}/${routes.wishLists}`}>
                  {t('wl_title')}
                </Link>
              ),
            },
            {
              title: <Link to="..">{t('admin_tabs_details')}</Link>,
            },
            {
              title: <span>{activeTab?.label}</span>,
            },
          ]}
        />
        <div className={styles.subheader}>
          <Title level={2}>{t('wl_settings_title')}</Title>
        </div>
        <Tabs
          activeKey={activeTab?.key}
          defaultActiveKey={items[0].key}
          items={visibleTabs}
          onChange={onChangeTab}
        />
        <Outlet />
      </div>
    </Flex>
  );
}
