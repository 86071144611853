import { Language } from './../../../constants/enums/languages';
import {
  endpoints,
  InvitationEntity,
  InvitationsListEntity,
  ListEntity,
  InvitationSwapValidator,
  WaitingListEntity,
  WaitingListGuestEntity,
  WaitingListInvitationValidator,
  WaitingListQuery,
  Price,
  FormTranslationType,
  JoinWaitingListValidator,
  JoinWLAsFanResponse,
  AcceptWaitingListValidator,
  FanGroupOwnerWaitingListEntity,
  CreateWishListBody,
  PreviewVoucherBody,
  ListEntityV2,
  WishListWithInvitations,
  PaginationQueryV2,
  WaitingListFiltersQuery,
  V2Prefix,
  FGOWaitingListWithRequestsEntity,
  FGOWishListsValidator,
  WLAdditionalInfo,
  CreateWishListsValidator,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export type TicketsUrl = 'exportedVoucherUrl' | 'exportedParkingTicketUrl';

export interface RemoveTicketsBody {
  originalNumberOfTickets: number;
  ticketsToRemove: number;
}

export interface AddTicketsBody {
  originalNumberOfTickets: number;
  ticketsToAdd: number;
}

export const fetchWaitingLists = (
  fanGroupId: string,
  params: WaitingListQuery
): Promise<ListEntity<WaitingListEntity>> =>
  api.get(
    `${endpoints.fan}/${endpoints.groups}/${fanGroupId}/${endpoints.waitingLists}`,
    { params }
  );

export const setWaitingListsFilters = (
  fanGroupId: string,
  params: WaitingListFiltersQuery
): Promise<ListEntity<WaitingListEntity>> => {
  return api.put(
    `v2/${endpoints.fan}/${endpoints.groups}/${fanGroupId}/${endpoints.waitingLists}/filter?maxPageSize=${params['maxPageSize']}&itemOffset=${params['itemOffset']}`,
    { ...params }
  );
};

export const fetchFanGroupOwnerWaitingLists = (
  fanGroupId: string,
  requestBody: FGOWishListsValidator,
  params: WaitingListQuery
): Promise<ListEntity<FanGroupOwnerWaitingListEntity>> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.waitingLists}/${endpoints.advancedSearch}`,
    {
      ...requestBody,
      mustHaveSeatsWaiting: false, // TODO
    },
    { params }
  );
};

export const fetchFanGroupOwnerWaitingList = (
  waitingListId: string
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.get(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}`
  );
};

export const createWaitingList = (
  fanGroupId: string,
  body: CreateWishListBody
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.post(
    `${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.waitingLists}`,
    body
  );
};

export const updateWaitingList = (
  waitingListId: string,
  body: CreateWishListBody
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}`,
    body
  );
};

export const updateWLEstimatedSeats = (
  waitingListId: string,
  estimatedTickets: number
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.estimatedTickets}`,
    { estimatedTickets }
  );
};

// PUT https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/listId/remove-tickets/

export const removeWishListTickets = (
  waitingListId: string,
  body: RemoveTicketsBody
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.removeTickets}`,
    body
  );
};

export const updateWaitingListToOpen = (
  waitingListId: string
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.open}`
  );
};
export const updateWaitingListToDraft = (
  waitingListId: string
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.draft}`
  );
};

export const updateWaitingListToPublish = (
  waitingListId: string
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.publish}`
  );
};

export const updateWaitingListSchedule = (
  waitingListId: string,
  body: { date: string }
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.schedule}`,
    body
  );
};

export const updateWaitingListImage = (
  waitingListId: string,
  body: { fileId: string }
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.waitingListImage}`,
    undefined,
    { params: body }
  );
};

export const deleteWaitingListImage = (
  waitingListId: string
): Promise<unknown> => {
  return api.delete(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.waitingListImage}`
  );
};

export const updateWaitingListTC = (
  waitingListId: string,
  body: { expirationDate: string; fileId: string }
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.tc}`,
    body
  );
};

export const updateWaitingListVoucherImage = (
  waitingListId: string,
  body: { fileId: string }
): Promise<unknown> => {
  return api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.venueImage}`,
    undefined,
    { params: body }
  );
};

export const previewWaitingListVoucher = (
  waitingListId: string,
  language: Language
): Promise<PreviewVoucherBody> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.previewVoucher}/${language}`
  );
};

export const updateWaitingListTickets = (
  waitingListId: string,
  body: AddTicketsBody
): Promise<FanGroupOwnerWaitingListEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.addTickets}`,
    body
  );
};

export const fetchFanGroupOwnerWaitingListNames = (
  fanGroupId: string
): Promise<ListEntity<string>> => {
  return api.get(
    `${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.waitingListNames}`
  );
};

export const fetchFanGroupOwnerWaitingListsWithRequests = (
  fanGroupId: string,
  params: WaitingListQuery
): Promise<ListEntityV2<FGOWaitingListWithRequestsEntity>> => {
  return api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.waitingListsWithRequests}`,
    { params }
  );
};

export const fetchJoinedWaitingLists = (
  params: WaitingListQuery
): Promise<ListEntity<WaitingListEntity>> =>
  api.get(`${endpoints.fan}/${endpoints.joinedWaitingLists}`, { params });

export const fetchWaitingList = (id: string): Promise<WaitingListEntity> =>
  api.get(`${endpoints.fan}/${endpoints.waitingLists}/${id}`);

export const fetchWaitingListVenueConditions = (
  id: string
): Promise<FormTranslationType> =>
  api.get(
    `${endpoints.fan}/${endpoints.waitingLists}/${id}/${endpoints.venueConditions}`
  );

export const addWaitingListInvitation = (
  waitingListId: string,
  guestId: string,
  body: WaitingListInvitationValidator
): Promise<WaitingListGuestEntity> =>
  api.post(
    `v2/host/${endpoints.waitingLists}/${waitingListId}/${endpoints.guest}/${guestId}`,
    body
  );

export const fetchWaitingListInvitations = (
  id: string
): Promise<InvitationsListEntity> =>
  api.get(
    `v2/host/${endpoints.waitingLists}/${id}/${endpoints.invitations}?size=9999&page=0`
  );

export const fetchInvitation = (
  invitationId: string
): Promise<InvitationEntity> =>
  api.get(`v2/host/${endpoints.invitations}/${invitationId}`);

export const deleteInvitation = (invitationId: string): Promise<unknown> =>
  api.delete(`v2/host/${endpoints.invitations}/${invitationId}`);

export const cancelWaitingListInvitation = (
  invitationId: string
): Promise<InvitationEntity> =>
  api.post(`v2/host/${endpoints.invitations}/${invitationId}/cancel`);

export const updateWaitingListInvitation = (
  invitationId: string,
  body: WaitingListInvitationValidator
): Promise<InvitationEntity> =>
  api.put(`v2/host/${endpoints.invitations}/${invitationId}`, body);

export const acceptWaitingListInvitation = (
  invitationId: string
): Promise<InvitationEntity> =>
  api.post(`v2/host/${endpoints.invitations}/${invitationId}/accept-seat`);

export const declineWaitingListInvitation = (
  invitationId: string
): Promise<InvitationEntity> =>
  api.post(`v2/host/${endpoints.invitations}/${invitationId}/reject-seat`);

// generates ticket file + URL
export const exportInvitationSeat = (
  invitationId: string
): Promise<InvitationEntity> =>
  api.put(
    `v2/host/${endpoints.invitations}/${invitationId}/${endpoints.exportSeat}`
  );

// generates parking ticket file + URL
export const exportInvitationParkingTicket = (
  invitationId: string
): Promise<InvitationEntity> =>
  api.put(
    `v2/host/${endpoints.invitations}/${invitationId}/${endpoints.exportParkingTicket}`
  );

// swap reserve invitations order
export const swapInvitationsOrder = (
  waitingListId: string,
  body: InvitationSwapValidator
): Promise<InvitationEntity> =>
  api.patch(
    `v2/host/${endpoints.waitingLists}/${waitingListId}/${endpoints.invitations}/swap`,
    body
  );

export const joinWaitingListAsFan = (
  waitingListId: string,
  body: JoinWaitingListValidator
): Promise<JoinWLAsFanResponse> =>
  api.post(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.position}`,
    body
  );

export const unjoinWaitingListAsFan = (
  waitingListId: string
): Promise<unknown> =>
  api.delete(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.position}`
  );

export const rejectWaitingListAsFan = (waitingListId: string): Promise<null> =>
  api.post(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.reject}`
  );

export const fanCancelWaitingList = (waitingListId: string): Promise<null> =>
  api.post(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.cancel}`
  );

export const fetchTickets = (
  fanGroupId: string,
  params: WaitingListQuery
): Promise<ListEntity<WaitingListEntity>> =>
  api.get(
    `${endpoints.fan}/${endpoints.groups}/${fanGroupId}/${endpoints.tickets}`,
    { params }
  );

export const fetchActiveWaitingLists = (
  params: WaitingListQuery
): Promise<ListEntity<WaitingListEntity>> =>
  api.get(`${endpoints.fan}/${endpoints.tickets}`, { params });

export const fetchJoinedWaitingList = (
  fanGroupId: string,
  params: WaitingListQuery
): Promise<ListEntity<WaitingListEntity>> =>
  api.get(
    `${endpoints.fan}/${endpoints.groups}/${fanGroupId}/${endpoints.myWaitingList}`,
    { params }
  );

export const fetchWaitingListPrice = (
  waitingListId: string,
  tickets: number
): Promise<Price> =>
  api.get(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.price}/${tickets}`
  );

export const acceptWaitingList = (
  body: AcceptWaitingListValidator,
  waitingListId: string
): Promise<WaitingListEntity> =>
  api.post(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.accept}`,
    body
  );

export const exportSeats = (waitingListId: string): Promise<unknown> =>
  api.put(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.exportSeat}`,
    { format: null }
  );

export const exportParkingTickets = (waitingListId: string): Promise<unknown> =>
  api.put(
    `v2/${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.exportParkingTicket}`
  );

// GET /api/v2/host/groups/{groupId}/waiting-lists-with-invitations
export const fetchWaitingListsWithInvitations = (
  fanGroupId: string,
  params: PaginationQueryV2
): Promise<ListEntityV2<WishListWithInvitations>> =>
  api.get(
    `v2/${endpoints.host}/${endpoints.groups}/${fanGroupId}/${endpoints.waitingListsWithInvitations}`,
    { params }
  );

// GET /api/fan-group-owner/waiting-lists/e218315f-2c89-4d5a-a46e-2bf62e0f96ab
export const fetchFGOWaitingList = (
  waitingListId: string
): Promise<FGOWaitingListWithRequestsEntity> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}`
  );

// DELETE /api/fan-group-owner/waiting-lists/e218315f-2c89-4d5a-a46e-2bf62e0f96ab
export const deleteFGOWaitingList = (waitingListId: string): Promise<unknown> =>
  api.delete(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}`
  );

// GET https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/distribution-finished/3
export const fetchFGOWaitingListDistributionFinished = (
  waitingListId: string,
  nextDistributionNumber: number
): Promise<{ distributionFinished: boolean }> =>
  api.get(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.distributionFinished}/${nextDistributionNumber}`
  );

// PUT https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/6530aaa5-9530-4a55-958d-a7ef604d47f1/close
export const fgoCloseWL = (waitingListId: string): Promise<unknown> =>
  api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.close}`
  );

// PUT https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/6530aaa5-9530-4a55-958d-a7ef604d47f1/shuffle
export const fgoShuffleWL = (waitingListId: string): Promise<unknown> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.shuffle}`
  );

export const fetchWLAdditionalInfo = (id: string): Promise<WLAdditionalInfo> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.waitingLists}/${id}/${endpoints.additionalInfos}`
  );

export const fetchHostWLAdditionalInfo = (
  id: string
): Promise<WLAdditionalInfo> =>
  api.get(
    `${V2Prefix}/${endpoints.host}/${endpoints.waitingLists}/${id}/${endpoints.additionalInfos}`
  );

export const fetchAdminWL = (id: string): Promise<WaitingListEntity> =>
  api.get(`${endpoints.seatersAdmin}/${endpoints.waitingLists}/${id}`);

export const updateAdminWL = (
  id: string,
  body: CreateWishListsValidator
): Promise<WaitingListEntity> =>
  api.put(`${endpoints.seatersAdmin}/${endpoints.waitingLists}/${id}`, body);

// PUT /api/v2/fan-group-owner/waiting-lists/{wlId}/filters

export const updateWaitingListFilters = (
  waitingListId: string,
  body: { filters: FanGroupOwnerWaitingListEntity['filters'] }
): Promise<unknown> => {
  return api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.filters}`,
    body
  );
};

export const reExportVoucher = (
  waitingListId: string,
  body: AcceptWaitingListValidator
): Promise<unknown> =>
  api.put(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.reExportVoucher}`,
    body
  );
