import {
  AxiosAPIError,
  InvitationEntity,
  InvitationsListEntity,
  ListEntity,
  InvitationSwapValidator,
  WaitingListEntity,
  WaitingListGuestEntity,
  WaitingListInvitationValidator,
  WaitingListQuery,
  waitingListsKeys,
  Price,
  FormTranslationType,
  JoinWaitingListValidator,
  JoinWLAsFanResponse,
  AcceptWaitingListValidator,
  waitingListInvitationsKeys,
  joinedWaitingListsKeys,
  waitingListTicketsKeys,
  WaitingListFiltersQuery,
  fanGroupOwnerWaitingListsKeys,
  FanGroupOwnerWaitingListEntity,
  CreateWishListBody,
  fanGroupOwnerWaitingListNames,
  PreviewVoucherBody,
  FGOWaitingListWithRequestsEntity,
  GuestEntity,
  waitingListConditionsKeys,
  FGOWishListsValidator,
  WLAdditionalInfo,
  CreateWishListsValidator,
  UserRole,
  profileKeys,
  LookEntity,
  Language,
} from '@seaters-app/constants';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { notification } from 'antd';
import { t } from 'i18next';
import {
  acceptWaitingList,
  acceptWaitingListInvitation,
  addWaitingListInvitation,
  cancelWaitingListInvitation,
  createWaitingList,
  declineWaitingListInvitation,
  deleteInvitation,
  exportInvitationParkingTicket,
  exportInvitationSeat,
  exportParkingTickets,
  exportSeats,
  fetchFanGroupOwnerWaitingList,
  fetchFanGroupOwnerWaitingListNames,
  fetchFGOWaitingList,
  fetchFanGroupOwnerWaitingLists,
  fetchJoinedWaitingLists,
  fetchTickets,
  fetchWaitingList,
  fetchWaitingListInvitations,
  fetchWaitingListPrice,
  fetchWaitingListVenueConditions,
  fetchWaitingLists,
  joinWaitingListAsFan,
  previewWaitingListVoucher,
  rejectWaitingListAsFan,
  setWaitingListsFilters,
  swapInvitationsOrder,
  unjoinWaitingListAsFan,
  updateWaitingList,
  updateWaitingListImage,
  updateWaitingListInvitation,
  updateWaitingListSchedule,
  updateWaitingListTC,
  updateWaitingListTickets,
  updateWaitingListToDraft,
  updateWaitingListToOpen,
  updateWaitingListToPublish,
  updateWaitingListVoucherImage,
  updateWLEstimatedSeats,
  fgoCloseWL,
  fgoShuffleWL,
  fetchWLAdditionalInfo,
  fetchHostWLAdditionalInfo,
  fanCancelWaitingList,
  deleteFGOWaitingList,
  fetchAdminWL,
  updateAdminWL,
  deleteWaitingListImage,
  updateWaitingListFilters,
  removeWishListTickets,
  RemoveTicketsBody,
  AddTicketsBody,
  reExportVoucher,
} from '../api/waitingLists';
import { useAppUserStore } from '../storage';
import { queryClient } from '../react-query';

export const useFetchWaitingLists = (
  fanGroupId: string,
  params: WaitingListQuery
) =>
  useQuery<ListEntity<WaitingListEntity>, Error>(
    waitingListsKeys.list(fanGroupId, params),
    () => fetchWaitingLists(fanGroupId, params),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useSetWaitingListsFilters = (
  fanGroupId: string
): UseMutationResult<
  ListEntity<WaitingListEntity>,
  AxiosAPIError<Error>,
  WaitingListFiltersQuery,
  unknown
> => {
  return useMutation(
    waitingListsKeys.list(fanGroupId, { maxPageSize: 20, itemOffset: 0 }),
    (filters: WaitingListFiltersQuery) =>
      setWaitingListsFilters(fanGroupId, filters)
  );
};

export const useFetchFanGroupOwnerWaitingLists = (
  fanGroupId: string,
  requestBody: FGOWishListsValidator,
  params: WaitingListQuery
) =>
  useQuery<ListEntity<FanGroupOwnerWaitingListEntity>, Error>(
    fanGroupOwnerWaitingListsKeys.filteredList(fanGroupId, requestBody, params),
    () => fetchFanGroupOwnerWaitingLists(fanGroupId, requestBody, params),
    {
      enabled: !!fanGroupId,
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchFanGroupOwnerWaitingList = (waitingListId: string) =>
  useQuery<FanGroupOwnerWaitingListEntity, Error>(
    fanGroupOwnerWaitingListsKeys.detail(waitingListId),
    () => fetchFanGroupOwnerWaitingList(waitingListId),
    {
      enabled: !!waitingListId,
      keepPreviousData: true,
    }
  );

export const useCreateWaitingList = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<WaitingListEntity>,
  { fanGroupId: string; body: CreateWishListBody },
  unknown
> => useMutation(({ fanGroupId, body }) => createWaitingList(fanGroupId, body));

export const useUpdateWaitingList = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<WaitingListEntity>,
  { waitingListId: string; body: CreateWishListBody },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ waitingListId, body }) => updateWaitingList(waitingListId, body),
    {
      onSuccess: (_, { waitingListId }) =>
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(waitingListId)
        ),
    }
  );
};

export const useUpdateWLEstimatedSeats = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<WaitingListEntity>,
  { waitingListId: string; estimatedTickets: number },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ waitingListId, estimatedTickets }) =>
      updateWLEstimatedSeats(waitingListId, estimatedTickets),
    {
      onSuccess: (_, { waitingListId }) =>
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(waitingListId)
        ),
    }
  );
};

export const useRemoveWishListTickets = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<WaitingListEntity>,
  {
    waitingListId: string;
    body: RemoveTicketsBody;
  },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ waitingListId, body }) => removeWishListTickets(waitingListId, body),
    {
      onSuccess: (_, { waitingListId }) =>
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(waitingListId)
        ),
    }
  );
};

export const useUpdateWaitingListToOpen = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<FanGroupOwnerWaitingListEntity>,
  { waitingListId: string },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ waitingListId }) => updateWaitingListToOpen(waitingListId),
    {
      onSuccess: (_, { waitingListId }) =>
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(waitingListId)
        ),
    }
  );
};

export const useUpdateWaitingListToDraft = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<FanGroupOwnerWaitingListEntity>,
  { waitingListId: string },
  unknown
> =>
  useMutation(({ waitingListId }) => updateWaitingListToDraft(waitingListId));

export const useUpdateWaitingListToPublish = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<FanGroupOwnerWaitingListEntity>,
  { waitingListId: string },
  unknown
> =>
  useMutation(({ waitingListId }) => updateWaitingListToPublish(waitingListId));

export const useUpdateWaitingListSchedule = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<FanGroupOwnerWaitingListEntity>,
  { waitingListId: string; body: { date: string } },
  unknown
> =>
  useMutation(({ waitingListId, body }) =>
    updateWaitingListSchedule(waitingListId, body)
  );

export const useUpdateWaitingListImage = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<FanGroupOwnerWaitingListEntity>,
  { waitingListId: string; body: { fileId: string } },
  unknown
> =>
  useMutation(
    ({ waitingListId, body }) => updateWaitingListImage(waitingListId, body),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(variables.waitingListId)
        );
      },
    }
  );

export const useDeleteWaitingListImage = (): UseMutationResult<
  unknown,
  AxiosAPIError<object>,
  { waitingListId: string },
  unknown
> =>
  useMutation(({ waitingListId }) => deleteWaitingListImage(waitingListId), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        fanGroupOwnerWaitingListsKeys.detail(variables.waitingListId)
      );
    },
  });

export const useUpdateWaitingListTC = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<FanGroupOwnerWaitingListEntity>,
  { waitingListId: string; body: { expirationDate: string; fileId: string } },
  unknown
> =>
  useMutation(({ waitingListId, body }) =>
    updateWaitingListTC(waitingListId, body)
  );

export const useUpdateWaitingListVoucherImage = (): UseMutationResult<
  unknown,
  AxiosAPIError<object>,
  { waitingListId: string; body: { fileId: string } },
  unknown
> => {
  return useMutation(({ waitingListId, body }) =>
    updateWaitingListVoucherImage(waitingListId, body)
  );
};

export const usePreviewWaitingListVoucher = (body: {
  waitingListId: string;
  language: Language;
}) =>
  useQuery<PreviewVoucherBody, Error>(
    waitingListsKeys.paymentInfo(body.waitingListId),
    () => previewWaitingListVoucher(body.waitingListId, body.language),
    {
      refetchOnWindowFocus: false,
    }
  );

export const useUpdateWaitingListTickets = (): UseMutationResult<
  FanGroupOwnerWaitingListEntity,
  AxiosAPIError<PreviewVoucherBody>,
  {
    waitingListId: string;
    body: AddTicketsBody;
  },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ waitingListId, body }) => updateWaitingListTickets(waitingListId, body),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(variables.waitingListId)
        );
      },
    }
  );
};

export const useFetchFanGroupOwnerWaitingListNames = (fanGroupId: string) =>
  useQuery<ListEntity<string>, Error>(
    fanGroupOwnerWaitingListNames.allPerFanGroup(fanGroupId),
    () => fetchFanGroupOwnerWaitingListNames(fanGroupId),
    {
      enabled: !!fanGroupId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchJoinedWaitingLists = (
  params: WaitingListQuery,
  fanGroupId: string
) =>
  useQuery<ListEntity<WaitingListEntity>, Error>(
    joinedWaitingListsKeys.allPerFanGroup(fanGroupId),
    () => fetchJoinedWaitingLists(params),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchActiveWaitingLists = (
  fanGroupId: string,
  params: WaitingListQuery
) =>
  useQuery<ListEntity<WaitingListEntity>, Error>(
    waitingListTicketsKeys.all(),
    () => fetchTickets(fanGroupId, params),
    {
      enabled: !!fanGroupId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchWaitingList = (waitingListId: string) =>
  useQuery<WaitingListEntity, Error>(
    waitingListsKeys.detail(waitingListId),
    () => fetchWaitingList(waitingListId),
    {
      enabled: !!waitingListId,
    }
  );

export const useFetchWLAdditionalInfo = (
  waitingListId: string,
  enabled: boolean
) =>
  useQuery<WLAdditionalInfo, Error>(
    waitingListsKeys.additionalInfo(waitingListId),
    () => fetchWLAdditionalInfo(waitingListId),
    {
      enabled: !!enabled,
    }
  );

export const useFetchHostWLAdditionalInfo = (
  waitingListId: string,
  enabled: boolean
) =>
  useQuery<WLAdditionalInfo, Error>(
    waitingListsKeys.additionalInfo(waitingListId),
    () => fetchHostWLAdditionalInfo(waitingListId),
    {
      enabled: !!enabled,
    }
  );

export const useCreateWaitingListInvitation = (
  waitingListId: string
): UseMutationResult<
  WaitingListGuestEntity,
  AxiosAPIError<WaitingListInvitationValidator>,
  {
    guestId: string;
    body: WaitingListInvitationValidator;
    guest?: GuestEntity;
    errorMessage?: string;
    errorDescription?: string;
  },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ guestId, body }) =>
      addWaitingListInvitation(waitingListId, guestId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(waitingListId)
        );
      },
      onError: (err, { guest, errorMessage, errorCode }) => {
        notification.error({
          message: `${errorMessage}: ${guest.firstName} ${guest.lastName} ${guest.guestEmail} `,
          description:
            err.response?.data.message === 'api_guest_already_invited'
              ? t(errorCode)
              : '',
          duration: 10,
        });
      },
    }
  );
};

export const useDeleteWaitingListInvitation = (waitingListId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ invitationId }: { invitationId: string }) =>
      deleteInvitation(invitationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(waitingListId)
        );
      },
    }
  );
};

export const useFetchWaitingListVenueConditions = (waitingListId: string) =>
  useQuery<FormTranslationType, Error>(
    waitingListConditionsKeys.allPerWaitingList(waitingListId),
    () => fetchWaitingListVenueConditions(waitingListId),
    {
      enabled: !!waitingListId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchWaitingListInvitations = (
  waitingListId: string,
  options?: Record<string, unknown>
) => {
  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const profileRoles = queryClient.getQueryData<{ roles: UserRole[] }>(
    profileKeys.fanGroupRoles(fanGroupData?.fanGroupId ?? '')
  );

  const isHost = profileRoles?.roles.includes(UserRole.HOST);

  const isEnabled = !!waitingListId && !!isHost;

  return useQuery<InvitationsListEntity, Error>(
    waitingListInvitationsKeys.allPerWaitingList(waitingListId),
    () => fetchWaitingListInvitations(waitingListId),
    {
      enabled: isEnabled,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useCancelWaitingListInvitation = (
  waitingListId: string
): UseMutationResult<
  InvitationEntity,
  AxiosAPIError<object>,
  { invitationId: string },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ invitationId }) => cancelWaitingListInvitation(invitationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(waitingListId)
        );
      },
    }
  );
};

export const useJoinWaitingListAsFan = (
  waitingListId: string
): UseMutationResult<
  JoinWLAsFanResponse,
  AxiosAPIError<JoinWaitingListValidator>,
  JoinWaitingListValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    joinedWaitingListsKeys.detail(waitingListId),
    (body: JoinWaitingListValidator) =>
      joinWaitingListAsFan(waitingListId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};

export const useUnjoinWaitingListAsFan = (waitingListId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ waitingListId }: { waitingListId: string }) =>
      unjoinWaitingListAsFan(waitingListId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          joinedWaitingListsKeys.detail(waitingListId)
        );
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};

export const useRejectWaitingListAsFan = (
  waitingListId: string
): UseMutationResult<
  null,
  AxiosAPIError<WaitingListEntity>,
  { waitingListId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(() => rejectWaitingListAsFan(waitingListId), {
    onSuccess: () => {
      queryClient.invalidateQueries(
        joinedWaitingListsKeys.detail(waitingListId)
      );
      queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
    },
  });
};

export const useFanCancelWaitingList = (
  waitingListId: string
): UseMutationResult<
  null,
  AxiosAPIError<WaitingListEntity>,
  { waitingListId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(() => fanCancelWaitingList(waitingListId), {
    onSuccess: () => {
      queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
    },
  });
};

export const useExportWaitingListSeat = (): UseMutationResult<
  unknown,
  AxiosAPIError<object>,
  { waitingListId: string },
  unknown
> => {
  return useMutation(({ waitingListId }) => exportSeats(waitingListId));
};

export const useExportWaitingListParkingTicket = (): UseMutationResult<
  unknown,
  AxiosAPIError<object>,
  { waitingListId: string },
  unknown
> => {
  return useMutation(({ waitingListId }) =>
    exportParkingTickets(waitingListId)
  );
};

export const useAcceptWaitingListInvitation = (
  waitingListId: string
): UseMutationResult<
  InvitationEntity,
  AxiosAPIError<object>,
  { invitationId: string },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ invitationId }) => acceptWaitingListInvitation(invitationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(waitingListId)
        );
      },
    }
  );
};

export const useDeclineWaitingListInvitation = (
  waitingListId: string
): UseMutationResult<
  InvitationEntity,
  AxiosAPIError<object>,
  { invitationId: string },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ invitationId }) => declineWaitingListInvitation(invitationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(waitingListId)
        );
      },
    }
  );
};

export const useUpdateWaitingListInvitation = (
  waitingListId: string
): UseMutationResult<
  InvitationEntity,
  AxiosAPIError<WaitingListInvitationValidator>,
  { invitationId: string; body: WaitingListInvitationValidator },
  unknown
> => {
  // const queryClient = useQueryClient();

  return useMutation(
    ({ invitationId, body }) => updateWaitingListInvitation(invitationId, body),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(
        //  waitingListInvitationsKeys.allPerWaitingList(waitingListId)
        // );
      },
    }
  );
};

export const useExportWaitingListInvitationSeat = (
  waitingListId: string
): UseMutationResult<
  InvitationEntity,
  AxiosAPIError<object>,
  { invitationId: string },
  unknown
> => {
  return useMutation(({ invitationId }) => exportInvitationSeat(invitationId));
};

export const useExportWaitingListInvitationParkingTicket = (
  waitingListId: string
): UseMutationResult<
  InvitationEntity,
  AxiosAPIError<object>,
  { invitationId: string },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ invitationId }) => exportInvitationParkingTicket(invitationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(waitingListId)
        );
      },
    }
  );
};

export const useSwapInvitationsOrder = (
  waitingListId: string
): UseMutationResult<
  InvitationEntity,
  AxiosAPIError<InvitationSwapValidator>,
  { body: InvitationSwapValidator },
  unknown
> => {
  // const queryClient = useQueryClient();

  return useMutation(({ body }) => swapInvitationsOrder(waitingListId, body), {
    onSuccess: () => {
      // queryClient.invalidateQueries(
      //  waitingListInvitationsKeys.allPerWaitingList(waitingListId)
      // );
    },
  });
};

export const useFetchWaitingListTickets = (
  waitingListId: string,
  tickets: number
) =>
  useQuery<Price, Error>(
    waitingListsKeys.detailPrice(waitingListId),
    () => fetchWaitingListPrice(waitingListId, tickets),
    {
      enabled: !!waitingListId && !!tickets,
      // keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useAcceptWaitingList = (
  waitingListId: string
): UseMutationResult<
  WaitingListEntity,
  AxiosAPIError<AcceptWaitingListValidator>,
  AcceptWaitingListValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: AcceptWaitingListValidator) =>
      acceptWaitingList(body, waitingListId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};
export const useReexportVoucher = (
  waitingListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<AcceptWaitingListValidator>,
  AcceptWaitingListValidator,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: AcceptWaitingListValidator) => reExportVoucher(waitingListId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};

export const useFetchFGOWaitingList = (waitingListId: string) =>
  useQuery<FGOWaitingListWithRequestsEntity, Error>(
    fanGroupOwnerWaitingListsKeys.detail(waitingListId),
    () => fetchFGOWaitingList(waitingListId),
    {
      enabled: !!waitingListId,
    }
  );

export const useFGOCloseWishList = (): UseMutationResult<
  unknown,
  AxiosAPIError<object>,
  { waitingListId: string },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ waitingListId }) => fgoCloseWL(waitingListId), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(
        fanGroupOwnerWaitingListsKeys.detail(variables.waitingListId)
      );
    },
  });
};

export const useFGOShuffleWishList = (waitingListId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ waitingListId }: { waitingListId: string }) =>
      fgoShuffleWL(waitingListId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          waitingListsKeys.positions(waitingListId)
        );
      },
    }
  );
};

export const useDeleteFGOWaitingList = (waitingListId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ waitingListId }: { waitingListId: string }) => {
      return deleteFGOWaitingList(waitingListId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(waitingListId)
        );
      },
    }
  );
};

export const useFetchAdminWaitingList = (waitingListId: string) => {
  const { user } = useAppUserStore();

  const isAdmin = user?.roles.includes(UserRole.ADMIN);

  return useQuery<WaitingListEntity, Error>(
    waitingListsKeys.detail(waitingListId),
    () => fetchAdminWL(waitingListId),
    {
      enabled: !!waitingListId && isAdmin,
    }
  );
};

export const useUpdateAdminWaitingList = (): UseMutationResult<
  WaitingListEntity,
  AxiosAPIError<WaitingListEntity>,
  {
    waitingListId: string;
    body: CreateWishListsValidator;
  },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ waitingListId, body }) => updateAdminWL(waitingListId, body),
    {
      onSuccess: (data, { waitingListId }) => {
        queryClient.invalidateQueries(
          fanGroupOwnerWaitingListsKeys.detail(waitingListId)
        );
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};

// updateWaitingListFilters
export const useUpdateWaitingListFilters = (
  waitingListId: string
): UseMutationResult<
  unknown,
  AxiosAPIError<Error>,
  { filters: FanGroupOwnerWaitingListEntity['filters'] },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: { filters: FanGroupOwnerWaitingListEntity['filters'] }) =>
      updateWaitingListFilters(waitingListId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListsKeys.detail(waitingListId));
      },
    }
  );
};
