import React, { useEffect, useState } from 'react';
import {
  getSlugFromUrl,
  // useDeleteRequestToJoinFG, --- for testing
  useFetchFGBySlug,
  useRequestFanAccessToProtectedFG,
  useUpdateRequestFanAccessToProtectedFG,
} from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import { Alert, Card, Form, Input, Modal, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useTranslation } from 'react-i18next';
const { Title } = Typography;

export function ProtectedCodeModal({
  isProtectedCodeModalShown,
  setProtectedCodeModalShown,
}: {
  isProtectedCodeModalShown: boolean;
  setProtectedCodeModalShown: (v: boolean) => void;
}) {
  const slug = getSlugFromUrl();
  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const { data: fanGroupBySlugData, isRefetching } = useFetchFGBySlug(
    slug ?? '',
    true
  );

  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState<string>('');
  const { mutate: requestAccess, isLoading: isRequestLoading } =
    useRequestFanAccessToProtectedFG(fanGroupBySlugData?.id ?? '', slug ?? '');

  const { mutate: updateRequestAccess, isLoading: isRequestUpdateLoading } =
    useUpdateRequestFanAccessToProtectedFG(
      fanGroupBySlugData?.id ?? '',
      slug ?? ''
    );

  // For testing:
  // const { mutate: deleteRequestAccess, isLoading: isRequestDeleteLoading } =
  //   useDeleteRequestToJoinFG(fanGroupBySlugData?.id ?? '', slug ?? '');

  const [isAlertShown, setAlertShown] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    if (fanGroupBySlugData?.membership.request) {
      await updateRequestAccess(
        { code },
        {
          onSuccess: () => {
            setAlertShown(true);
          },
        }
      );
    } else {
      await requestAccess(
        { code },
        {
          onSuccess: () => {
            setAlertShown(true);
          },
        }
      );
    }
  };
  const description = fanGroupBySlugData?.protectionCodeExplanation
    ? fanGroupBySlugData?.protectionCodeExplanation
    : t('unlock_text_fangroup_reserved', {
        fangroup: fanGroupBySlugData?.name[lang]
          ? fanGroupBySlugData?.name[lang]
          : fanGroupBySlugData?.name['en'],
      });

  useEffect(() => {
    setIsLoading(isRequestLoading || isRequestUpdateLoading || isRefetching);
  }, [isRefetching]);

  return (
    <Modal
      title={<Title level={3}>{t('general_protected_group')}</Title>}
      onCancel={() => {
        setAlertShown(false);
        setProtectedCodeModalShown(false);
      }}
      open={isProtectedCodeModalShown}
      footer={null}
      width={400}
      centered
      style={{ maxHeight: '800px' }}
      destroyOnClose={true}
    >
      <Form layout="vertical">
        <Form.Item label={t('enter_access_code_text')}>
          <Input
            onChange={(e) => setCode(e.target.value)}
            placeholder={
              t('type_here_placeholder')
                ? (t('type_here_placeholder') as string)
                : 'Type here'
            }
          />
        </Form.Item>

        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '20px',
            marginBottom: '24px',
          }}
        >
          <Button
            style={{ flexGrow: 1 }}
            onClick={() => {
              setProtectedCodeModalShown(false);
              setAlertShown(false);
            }}
          >
            {t('button_text_cancel')}
          </Button>
          <Button
            style={{ flexGrow: 1 }}
            type="primary"
            onClick={onSubmit}
            // loading={isRequestLoading || isRequestUpdateLoading || isRefetching}
            loading={isLoading}
          >
            {t('button_text_send')}
          </Button>
          {/* For testing purpose: */}
          {/* <Button        
            style={{ flexGrow: 1 }}
            type="primary"
            onClick={() => deleteRequestAccess()}
            loading={isRequestDeleteLoading || isRefetching}
          >
            Delete request
          </Button> */}
        </div>
        {fanGroupBySlugData?.membership.request?.rejectionReason &&
          isAlertShown && (
            <Alert
              style={{ marginBottom: 16 }}
              message={fanGroupBySlugData?.membership.request?.rejectionReason}
              type="error"
            />
          )}
        <Card>
          <Meta description={description} />
        </Card>
      </Form>
    </Modal>
  );
}

export default ProtectedCodeModal;
