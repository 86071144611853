import i18next, { changeLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import backend, { HttpBackendOptions } from 'i18next-http-backend';

import {
  SESSION_STORAGE_TARGET_APP,
  LOCAL_STORAGE_USER_LOCALE,
  Language,
  TranslationListEntity,
  TranslationsTarget,
} from '@seaters-app/constants';
import { fetchTranslations } from '@seaters-app/data-access';

const loadResources = async () => {
  let currentLocale =
    localStorage.getItem(LOCAL_STORAGE_USER_LOCALE)?.replaceAll('"', '') ||
    undefined;

  if (currentLocale.includes('-')) {
    currentLocale = currentLocale.slice(0, 2);
    changeLanguage(currentLocale);
    localStorage.setItem(LOCAL_STORAGE_USER_LOCALE, currentLocale);
  }

  const currentTarget =
    sessionStorage.getItem(SESSION_STORAGE_TARGET_APP)?.replaceAll('"', '') ||
    undefined;

  const existLang = Object.values(Language).find((el) => el === currentLocale);

  return await fetchTranslations({
    lang: existLang || 'en',
    itemOffset: 0,
    maxPageSize: 10000,
    target: currentTarget || TranslationsTarget.WEB,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
};

const backendOptions: HttpBackendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  customHeaders: {
    // Seems to cause issues in case the translation file is cached
    'Cache-Control': 'no-cache',
  },
  request: (options, url, payload, callback) => {
    try {
      loadResources().then((response) => {
        const translations: {
          [key: string]: string;
        } = {};

        if (response) {
          response.items.forEach((item: TranslationListEntity) => {
            translations[item.key] = item.text;
          });
        }

        callback(null, {
          data: translations,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        data: {},
        status: 500,
      });
    }
  },
};

i18next
  .use(
    new LanguageDetector(null, {
      lookupLocalStorage: LOCAL_STORAGE_USER_LOCALE,
    })
  )
  .use(initReactI18next)
  .use(backend)
  .init({
    backend: backendOptions,
    fallbackLng: false,
    // lng: 'en', // if you're using a language detector, do not define the lng option
    react: {
      useSuspense: false,
    },
    returnNull: false,
    // debug: true,
    // resources,
    // defaultNS,
  });

export default i18next;
